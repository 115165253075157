// Colors
$primary: #1B2953;
$secondary: #007DC5;
$tertiary: #0AA2CC;
$body-bg: white;
$dark-gray: #37474F;
$primary-opacity: #e6e8ec;
$secondary-opacity: #e4f1f9;
$red: #8E1F23;
$aquamarine: #77D7DB;
$peach: #F2CD8AD8;

// Layout
$navbar-padding-y: 1rem;

// Typography
$headings-color: $primary;
$font-family-base: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;

$font-weight-light: 400;
$font-weight-bold: 600;
$font-size-title-feature: 2rem;

// Utilities
$border-radius: 10px;

// Buttons
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 3rem;

// Spacers
$spacer: 1rem;
$spacers: (
  6: ($spacer * 6),
  7: ($spacer * 12),
  8: ($spacer * 24),
  9: ($spacer * 34)
);

// Cards
$card-border-color: none;

// Shadow
$box-shadow-primary: 0px 20px 20px #00000029;
